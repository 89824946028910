



















































































































import { Component, Vue, Prop } from "vue-property-decorator";
import dateHelper from "@/Scripts/utilities/date-helper";
//  types
import { SpeedLossStatistic } from "@/types/SpeedLossStatistic";
import { VesselEvent } from "@/types/vesselEvent";
import { FoulingAddedConsumption } from "@/types/FoulingAddedConsumption";

@Component({})
export default class SpeedLossCard extends Vue {
  @Prop({ required: true }) readonly latestSpeedLossStatistics!: SpeedLossStatistic | null;
  @Prop({ required: true }) readonly latestTrendEvent!: VesselEvent;
  @Prop({ required: true }) readonly addedFuelConsumption!: FoulingAddedConsumption;
  @Prop() readonly isBenchmarking!: boolean;

  // @Getters
  get latestSpeedLossPercentage(): number | string {
    if (this.latestSpeedLossStatistics == null) return "N/A";
    return Math.abs(this.latestSpeedLossStatistics.trendEndValue * -1).toFixed(1);
  }

  get benchmarkLevel(): number | string {
    if (this.latestSpeedLossStatistics == null) return "N/A";
    return this.latestSpeedLossStatistics.benchmark.level.toFixed(1);
  }

  get isLoss(): boolean {
    if (!this.latestSpeedLossStatistics) return true;
    return this.latestSpeedLossStatistics.trendEndValue <= 0;
  }

  get greenZoneEnds(): number {
    if (!this.latestSpeedLossStatistics) return 0 - 5;
    return this.latestSpeedLossStatistics.benchmark.level - 5;
  }

  get yellowZoneEnds(): number {
    return this.greenZoneEnds - 5;
  }

  get state(): string {
    if (this.isBenchmarking) return "benchmarking";
    if (this.latestSpeedLossStatistics == null) return "N/A";
    if (this.latestSpeedLossStatistics.trendEndValue < this.yellowZoneEnds) return "high";
    if (this.latestSpeedLossStatistics.trendEndValue < this.greenZoneEnds && this.latestSpeedLossStatistics.trendEndValue > this.yellowZoneEnds) return "medium";
    if (this.latestSpeedLossStatistics.trendEndValue > this.greenZoneEnds) return "normal";
    return "N/A";
  }

  get statecolor(): string {
    switch (this.state) {
      case "high":
        return "#ff5252";
      case "medium":
        return "#ffc107";
      case "normal":
        return "#4caf50";
      case "benchmarking":
        return "#003B42";
      default:
        return "#fff";
    }
  }

  get stateicon(): string {
    switch (this.state) {
      case "high":
        return "mdi-alert-circle";
      case "medium":
        return "mdi-alert-circle";
      case "normal":
        return "mdi-check-circle";
      case "benchmarking":
        return "mdi-gauge";
      default:
        return "mdi-check-circle";
    }
  }

  get baselineTooltipText(): string {
    return "The baseline is used to establish the 0% performance of the vessel, all of the speed loss values are calculated in relation to this baseline. The speed loss percentage you see in the top of this box in large text is relative to the Baseline.";
  }

  get benchmarkTooltipText(): string {
    return "The benchmark is used to calculate the vessel's performance against a recent trend event. The benchmark determines the status of the vessel as green, yellow, or red. A user can set an automatic benchmark based on the first 120 data points after the event, or they can manually set a benchmark relative to the baseline performance.";
  }

  get cumulativeAddedConsumptionTooltipText(): string {
    return "This metric reports the cumulative additional fuel consumption the vessel has consumed relative to its benchmark performance level over the most recent trend event period. The metric starts at 0 when a new trend event is created and it gets larger as the performance of the vessel degrades over the trend period. The cumulative additional consumption is calculated by adding up each day's additional consumption compared to the benchmark performance based on the vessel's actual measured speeds and drafts. The vessel's SFR shop curve is used to translate the power differences into additional fuel consumption.";
  }

  get classes(): any[] {
    return ["speed-loss", this.state];
  }

  get formatedLatestTrendEventDate(): string | undefined {
    if (!this.latestTrendEvent.timestamp || this.latestSpeedLossStatistics?.benchmark.isManual) return;
    return dateHelper.getFormatedDateString(this.latestTrendEvent.timestamp);
  }

  get benchmarkFormatedEndDate(): string | undefined {
    if (!this.latestSpeedLossStatistics?.benchmark.end) return;
    return dateHelper.getFormatedDateString(this.latestSpeedLossStatistics.benchmark.end);
  }

  get addedFuelConsumptionRate(): number | undefined {
    if (!this.addedFuelConsumption) return;
    return Number(Number(this.addedFuelConsumption.periodEndAddedConsumptionRate).toFixed(2));
  }

  get cumulativeAddedFuelConsumption(): string | undefined {
    if (!this.addedFuelConsumption || !this.latestTrendEvent || this.isBenchmarking) return;
    if (this.addedFuelConsumption.fromDate !== this.latestTrendEvent.timestamp) {
      return "N/A";
    }
    const roundedNumber = Math.round(Number(this.addedFuelConsumption.cumulativeAddedConsumption));
    return roundedNumber.toLocaleString();
  }

  formatColorLegendValue(value: number): string {
    // Showing "opposite" sign because "speed loss" already indicates a negative number.
    const adjustedValue = -value;
    return adjustedValue.toFixed(1);
  }

  round(value: number, precision = 1): number {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }
}
